<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body">


                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">原密码 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="password" class="form-control" placeholder="请输入原密码" v-model="data_form.oldpwd" name="oldpwd">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">新密码 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="password" class="form-control" placeholder="请输入新密码" v-model="data_form.password" name="password">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">确认密码 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="password" class="form-control" placeholder="请输入确认密码" v-model="data_form.repwd" name="repwd">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";

    import {updatePwd} from '@/api/user';

    export default {
        name: "user_add",
        props: {
            title: String,
            dialog_data: {
                dialog_data: Object
            }
        },
        created:function() {

        },
        components :{

        },
        data() {
            return {
                data_form:{
                    oldpwd: '',
                    password: '',
                    repwd: '',
                    user_id: this.dialog_data.form_data.user_id,
                },
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    oldpwd: {
                        validators: {
                            notEmpty: {
                                message: "原密码不能为空"
                            }
                        }
                    },
                    password: {
                        validators: {
                            notEmpty: {
                                message: "新密码不能为空"
                            }
                        }
                    },
                    repwd: {
                        validators: {
                            notEmpty: {
                                message: "用户密码不能为空"
                            },
                            identical: {
                                compare: function () {
                                    return data_form.querySelector('[name="password"]').value;
                                },
                                message: "两次密码不一致"
                            }
                        }
                    },

                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                updatePwd(_this.data_form).then(function (res){
                    if (res) {

                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            menuTreeSelect(array) {
                this.menuSelectNode = [];
                var index = array.length - 1;
                this.menuSelectNode.push(array[index]);
                this.data_form.menu_id = this.menuSelectNode[0];
                this.$refs["menu_id_input"].parentElement.lastChild.remove();
            }
        }
    }
</script>
