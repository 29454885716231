<template>
  <ul class="menu-nav">


    <router-link
        v-for="(item) in kt_menu"
        :key="item.id"
        :to="item.href"
        v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon" v-bind:class="[item.icon]"></i>
          <span class="menu-text">{{item.name}}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  props:{
    kt_menu:{
      type:Array,
      default:null
    }
  },
  methods: {

  }
};
</script>
