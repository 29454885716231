<template>
  <div>
    <a
      href="#"
      class="btn btn-icon btn-clean btn-lg w-40px h-40px"
      id="kt_quick_user_toggle"
      data-toggle="tooltip"
      data-placement="right"
      data-container="body"
      data-boundary="window"
      title=""
      data-original-title="User Profile"
    >
      <span class="symbol symbol-30 symbol-lg-40">
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/General/User.svg" />
          <!--end::Svg Icon-->
        </span>
        <!--<span class="symbol-label font-size-h5 font-weight-bold">S</span>-->
      </span>
    </a>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-left p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          用户中心
          <small class="text-muted font-size-sm ml-2"></small>
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="picture" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{user.nickname}}
            </a>
            <div class="text-muted mt-1">{{user.role_name}}</div>
            <div class="navi mt-2">
              <a href="javascript:void(0);" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                   <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo3\dist/../src/media/svg/icons\Communication\Adress-book1.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M17,2 L19,2 C20.6568542,2 22,3.34314575 22,5 L22,19 C22,20.6568542 20.6568542,22 19,22 L17,22 L17,2 Z" fill="#000000" opacity="0.3"/>
                            <path d="M4,2 L16,2 C17.6568542,2 19,3.34314575 19,5 L19,19 C19,20.6568542 17.6568542,22 16,22 L4,22 C3.44771525,22 3,21.5522847 3,21 L3,3 C3,2.44771525 3.44771525,2 4,2 Z M11.1176481,13.709585 C10.6725287,14.1547043 9.99251947,14.2650547 9.42948307,13.9835365 C8.86644666,13.7020183 8.18643739,13.8123686 7.74131803,14.2574879 L6.2303083,15.7684977 C6.17542087,15.8233851 6.13406645,15.8902979 6.10952004,15.9639372 C6.02219616,16.2259088 6.16377615,16.5090688 6.42574781,16.5963927 L7.77956724,17.0476658 C9.07965249,17.4810276 10.5130001,17.1426601 11.4820264,16.1736338 L15.4812434,12.1744168 C16.3714821,11.2841781 16.5921828,9.92415954 16.0291464,8.79808673 L15.3965752,7.53294436 C15.3725414,7.48487691 15.3409156,7.44099843 15.302915,7.40299777 C15.1076528,7.20773562 14.7910703,7.20773562 14.5958082,7.40299777 L13.0032662,8.99553978 C12.5581468,9.44065914 12.4477965,10.1206684 12.7293147,10.6837048 C13.0108329,11.2467412 12.9004826,11.9267505 12.4553632,12.3718698 L11.1176481,13.709585 Z" fill="#000000"/>
                        </g>
                    </svg><!--end::Svg Icon-->
                   </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{user.phone}}
                  </span>
                </span>
              </a>
            </div>

            <div class="card-toolbar">
              <button class="btn btn-light-primary mr-2 pull-right" @click="updatePwd">
                修改密码
              </button>
              <button class="btn btn-light-primary mr-2" @click="onLogout">
                退出登录
              </button>
            </div>

          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">

          <div class="form-group row">
            <label class="col-lg-3 col-form-label">昵称:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control" placeholder="请输入昵称" v-model="user.nickname" name="nickname">
              <span class="form-text text-muted"></span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label">手机号码:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control" placeholder="请输入手机号码" v-model="user.phone" name="phone">
              <span class="form-text text-muted"></span>
            </div>
          </div>
          <button class="btn btn-light-primary" @click="updateUser">保存</button>

        </div>
        <!--end::Nav-->
        <div class="separator separator-dashed my-7"></div>
        <!--begin::Notifications-->

        <!--end::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>

    <v-dialog v-model="dialog_show" persistent max-width="700px">
      <component
              :key="dialog_id"
              :title="dialog_title"
              :visible="dialog_show"
              :is="dialog_view"
              :dialog_data="dialog_data"
              @opeareResultHandler="opeareResultHandler"></component>
    </v-dialog>
  </div>

</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { getUserInfo} from '@/api/index';
import updatePwd from '@/view/pages/index/UpdatePwd';
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import {editAdminUser} from '@/api/user';

export default {
  name: "KTQuickUser",
  created: function() {
    var _this = this;
    getUserInfo({}).then(function (res) {
      if (res && res.response) {
        _this.user = res.response;
      }
    });
  },
  data() {
    return {
      user: {},
      dialog_id: 0,
      dialog_show: false,
      dialog_title: '',
      dialog_view: null,
      dialog_data: null,
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      //缓存token登录秘钥
      localStorage.removeItem('psim_Authorization_Token');

      //跳转到登录页
      this.$router.push('/login');
    },
    updatePwd() {
      this.dialog_id = new Date().getTime();
      this.dialog_show = true;
      this.dialog_title = '修改密码';
      this.dialog_view = updatePwd;
      this.dialog_data = {
        form_data: {
          user_id: this.user.id
        }
      };
    },
    updateUser() {

      var _this = this;
      editAdminUser({
        'id': this.user.id,
        'nickname': this.user.nickname,
        'phone': this.user.phone,
        'username': this.user.username
      }).then(function (res){
        if (res) {
          KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
        }
      });
    },
    opeareResultHandler(result) {
      this.dialog_show = false;
    }
  },
  computed: {
    picture() {
      return process.env.BASE_URL + "media/users/default.jpg";
    }
  }
};
</script>
