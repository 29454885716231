<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div
      class="aside-primary d-flex flex-column align-items-center flex-row-auto"
    >
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
        class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
        style="height: 528px; overflow: hidden;"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column" role="tablist">
          <template v-for="(item, index) in menu_data">
            <li :key="item.id"
                class="nav-item mb-3"
                data-placement="right"
                data-container="body"
                data-boundary="window"
                v-b-tooltip.hover.right="item.name">
              <template v-if="item.children">
                <template v-if="item.children.length == 1 && item.children[0].href == item.href">
                  <router-link v-bind:to='item.href'
                               class="nav-link btn btn-icon btn-clean btn-lg"
                               data-toggle="tab">

                  <span class="svg-icon svg-icon-xl">
                    <i v-bind:class="[item.icon]"></i>
                  </span>
                  </router-link>
                </template>
                <template v-else>
                  <a href="javascript:void(0);"
                     class="nav-link btn btn-icon btn-clean btn-lg"
                     :class = "[isActive(item)?'active':'']"
                     data-toggle="tab"
                     v-on:click="setActiveTab"
                     v-bind:data-tab="index">
                    <span class="svg-icon svg-icon-xl">
                      <i v-bind:class="[item.icon]"></i>
                    </span>
                  </a>
                </template>
              </template>
              <template v-else>
                <router-link v-bind:to='item.href'
                             class="nav-link btn btn-icon btn-clean btn-lg"
                             data-toggle="tab">
                  <span class="svg-icon svg-icon-xl">
                      <i v-bind:class="[item.icon]"></i>
                  </span>
                </router-link>
              </template>

            </li>
          </template>
        </ul>
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->
      <!--begin::Footer-->
      <div
        class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
        <!--begin::Aside Toggle-->
        <span
          class="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
          id="kt_aside_toggle"
          ref="kt_aside_toggle"
          data-placement="right"
          data-container="body"
          data-boundary="window"
          v-b-tooltip.hover.right="'Toggle Aside'"
        >
          <i class="ki ki-bold-arrow-back icon-sm"></i>
        </span>
        <!--end::Aside Toggle-->



        <!--begin::User-->
        <KTQuickUser></KTQuickUser>
        <!--end::User-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
    <!--begin::Secondary-->
    <div class="aside-secondary d-flex flex-row-fluid">
      <!--begin::Workspace-->
      <div
        class="aside-workspace scroll scroll-push my-2 ps"
        style="height: 824px; overflow: hidden;"
      >
        <!--begin::Tab Content-->
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <template v-for="(item) in menu_data">
            <template v-if="item.children && item.children.length >= 1">
               <b-tab v-if="isActive(item)"
                     active
                     class="p-3 px-lg-7 py-lg-5"
                     :key = "item.id">
                <div>
                  <div class="aside-menu-wrapper flex-column-fluid px-10 py-5"
                       id="kt_aside_menu_wrapper">
                    <div ref="kt_aside_menu"
                         id="kt_aside_menu"
                         class="aside-menu min-h-lg-800px"
                         data-menu-vertical="1"
                         data-menu-scroll="1"
                         v-bind:class="asideMenuClass">

                        <!-- example static menu here -->
                        <perfect-scrollbar
                              class="aside-menu scroll"
                              style="max-height: 90vh; position: relative;">
                          <KTMenu :key="item.id" :kt_menu="item.children"></KTMenu>
                        </perfect-scrollbar>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab v-else
                     class="p-3 px-lg-7 py-lg-5"
                     :key = "item.id">
                <div>
                  <div class="aside-menu-wrapper flex-column-fluid px-10 py-5"
                       id="kt_aside_menu_wrapper">
                    <div ref="kt_aside_menu"
                         id="kt_aside_menu"
                         class="aside-menu min-h-lg-800px"
                         data-menu-vertical="1"
                         data-menu-scroll="1"
                         v-bind:class="asideMenuClass">

                      <!-- example static menu here -->
                      <perfect-scrollbar
                              class="aside-menu scroll"
                              style="max-height: 90vh; position: relative;">
                        <KTMenu :key="item.id" :kt_menu="item.children"></KTMenu>
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
              </b-tab>
            </template>
          </template>
        </b-tabs>
        <!--end::Tab Content-->
        <!--end::Workspace-->
      </div>
      <!--end::Secondary-->
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";
import KTMenu from "@/view/layout/aside/Menu.vue";

import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";

import {getMenu} from '@/api/index';

export default {
  name: "KTAside",
  created: function () {
    var _this = this;
    getMenu(null).then(function(res){
      if(res){
        _this.menu_data = res.response;
      }
    });
  },
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 0,
      menu_data: null,
    };
  },
  components: {
    KTBrand,
    KTMenu,
    KTQuickUser,

  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);

    });
  },
  methods: {
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }
      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      target.classList.add("active");
    },
    isActive(value) {

      //判断路径是否在菜单列表中
      var _this = this;
      var result = false;
      if(value.children && value.children.length > 0){
        value.children.forEach(function(childern) {
          if(childern.href == _this.$route.path || _this.$route.path.indexOf(childern.href) > -1) {
            result = true;
          }
        });
      } else {
        if(value.href == _this.$route.path) {
          result = true;
        }
      }
      return result;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),


    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
