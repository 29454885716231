import request from './request';

export function login(params){
  return request({
    url: 'user/login',
    method: 'post',
    data: params
  });
}

export function phoneLogin(params){
  return request({
    url: 'user/phoneLogin',
    method: 'post',
    data: params
  });
}

export function checkUserName(params){
  return request({
    url: 'user/checkUserName',
    method: 'post',
    data: params
  });
}

export function checkPhone(params){
  return request({
    url: 'user/checkPhone',
    method: 'post',
    data: params
  });
}

export function register(params){
  return request({
    url: 'user/register',
    method: 'post',
    data: params
  });
}


export function getSmsCode(params){
  return request({
    url: 'user/getSmsCode',
    method: 'post',
    data: params
  });
}

export function checkSmsCode(params){
  return request({
    url: 'user/checkSmsCode',
    method: 'post',
    data: params
  });
}

export function updatePwd(params){
  return request({
    url: 'user/updatePwd',
    method: 'post',
    data: params
  });
}

export function updateUser(params){
  return request({
    url: 'user/updateUser',
    method: 'post',
    data: params
  });
}

export function getUserList(params){
  return request({
    url: 'adminUser/getUserList',
    method: 'post',
    data: params
  });
}

export function getUserRoleList(params){
  return request({
    url: 'adminUser/getUserRoleList',
    method: 'post',
    data: params
  });
}

export function addAdminUser(params){
  return request({
    url: 'adminUser/addAdminUser',
    method: 'post',
    data: params
  });
}


export function editAdminUser(params){
  return request({
    url: 'adminUser/editAdminUser',
    method: 'post',
    data: params
  });
}

export function deleteUser(params){
  return request({
    url: 'adminUser/deleteUser',
    method: 'post',
    data: params
  });
}






